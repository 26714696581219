import React from 'react'
import { Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'

import Link from '~/src/components/link'

import styles from './styles'

const useStyles = makeStyles(styles)

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Container
        maxWidth="lg"
        className={classes.footerContainer}
      >
        <div
          className={classes.footerSocial}
        >
          <Link
            to="https://www.instagram.com/majin.land"
            target="_blank"
            title="Follow Majin on Instagram"
          >
            <InstagramIcon className={classes.iconSocial} />
          </Link>
          <Link
            to="https://www.facebook.com/majin.land.dev"
            target="_blank"
            title="Follow Majin on Facebook"
          >
            <FacebookIcon className={classes.iconSocial} />
          </Link>
        </div>
        <div className={classes.termsPolicyContainer}>
          <Link
            to="/terms-and-conditions"
            target="_blank"
            className={classes.linkText}
          >
            Terms & Conditions
          </Link>
          <Link
            to="/privacy-policy"
            target="_blank"
            className={classes.linkText}
          >
            Privacy Policy
          </Link>
          <Typography
            variant="caption"
            className={classes.footerCopyright}
          >
            @ 2021 Majin | All rights reserved
          </Typography>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
