import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { Link as MuiLink } from '@material-ui/core'

// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links

const Link = (props) => {
  const { to, target, children, ...otherProps } = props

  const internal = /^\/(?!\/)/.test(to)

  if (!internal) {
    return (
      <MuiLink
        href={to}
        target={target || '_blank'}
        rel="noreferrer"
        {...otherProps}
      >
        {children}
      </MuiLink>
    )
  }

  return (
    <GatsbyLink
      to={to}
      target={target || '_self'}
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Link.defaultProps = {
  target: '',
}

export default Link
