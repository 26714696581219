import React from 'react'
import Helmet from 'react-helmet'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'

import ErrorBoundary from '../error-boundary'
import Footer from '../footer'
import NavBar from '../navbar'

import muitheme from '~/src/muitheme'
import styles from '~/src/globalStyles'
import './global.css'

const theme = createMuiTheme(muitheme)
const useStyles = makeStyles(styles)

const Page = ({ children }) => {
  // Use styles here to ensure global styles can receive theme
  useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: 'id',
        }}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="true" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" crossOrigin="true" />
      </Helmet>
      <CssBaseline />
      <NavBar />
      <main style={{ position: 'relative' }}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export default Page
