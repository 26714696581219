import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import Link from '../link'

const DefaultErrorFallback = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '8rem',
        paddingBottom: '8rem',
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
      }}
    >
      <ErrorOutlineOutlinedIcon
        fontSize="large"
        color="primary"
      />
      <Typography>
        Page not found
      </Typography>
      <Link to="/">
        Home
      </Link>
    </div>
  )
}

const ErrorFallback = ({ error, errorRenderer }) => {
  return errorRenderer ?
    errorRenderer(error.toString()) :
    <DefaultErrorFallback />
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  errorRenderer: PropTypes.func,
}

ErrorFallback.defaultProps = {
  errorRenderer: null,
}

const ErrorBoundary = ({ errorRenderer, children, errorCallback }) => {
  return (
    <ReactErrorBoundary
      onError={errorCallback}
      FallbackComponent={props => (
        <ErrorFallback
          errorRenderer={errorRenderer}
          {...props}
        />
      )}
    >
      {children}
    </ReactErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorRenderer: PropTypes.func,
  errorCallback: PropTypes.func,
}

ErrorBoundary.defaultProps = {
  errorRenderer: null,
  errorCallback: () => null,
}

export default ErrorBoundary
