export default (theme) => {
  const {
    color,
  } = theme

  return {
    toolbar: {
      flexWrap: 'wrap',
    },

    gutters: {
      padding: '0',
    },

    toolbarTitle: {
      flexGrow: 1,
      padding: '0.5rem 0',
    },

    logo: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        width: '8.75rem',
        height: 'auto',
      },
    },

    iconButton: {
      background: color.primary,
      borderRadius: '0.5rem',
      color: color.white,
      width: '2.25rem',
      height: '2.25rem',
    },

    listItem: {
      borderTop: `1px solid ${color.aliceBlue}`,
    },

    link: {
      color: color.textPrimary,
      fontSize: '1rem',
      textAlign: 'center',
      display: 'inline-block',
      width: '6rem',
      '&:hover': {
        fontWeight: '600',
      },
    },

    linkListItem: {
      color: color.textPrimary,
      fontSize: '1rem',
      '&:hover': {
        fontWeight: '600',
      },
    },

    activeLink: {
      fontWeight: '600',
    },
  }
}
