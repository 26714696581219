import muitheme from '~/src/muitheme'

export default () => {
  const {
    white,
  } = muitheme.color

  return {
    '@global': {
      html: {
        fontSize: '16px',
        fontFamily: 'Poppins, sans-serif',
      },

      a: {
        textDecoration: 'none',
      },

      body: {
        backgroundColor: white,
      },
    },
  }
}
