/* Global */

const global = {
  // Maximum content width when resolution is large
  maxContentWidth: 1120,
  // Width of logo
  logoWidth: 110,
}

/* Colors */
const color = {
  error: '#E30C07',
  textPrimary: '#373446',
  transparent: 'rgba(0, 0, 0, 0)',
  opaque: 'rgba(255, 255, 255, 0.94)',
  white: '#fff',
  aliceBlue: '#EDF2F4',
  red: '#E4627B',
  green: '#CEFF7D',
  brown: '#FACA6C',
  purple: '#7250F8',
  pink: '#E8657D',
  cyan: '#70FFE9',
  grey: '#F7F9FA',
  opaqueGrey: 'rgba(247, 249, 250, 0.50)',
  yellow: '#FDD855',
  silver: '#F7F9FA',
  monsoon: '#878590',
  lavenderGray: '#BEB8D8',
}

color.primary = color.purple
color.primaryLight = '#8669f9'
color.primaryDark = '#5e37f7'
color.secondary = color.pink
color.secondaryLight = '#eb7b90'
color.secondaryDark = '#e54f6a'

const palette = {
  primary: {
    main: color.primary,
    light: color.primaryLight,
    dark: color.primaryDark,
  },
  secondary: {
    main: color.secondary,
    light: color.secondaryLight,
    dark: color.secondaryDark,
  },
  error: {
    main: color.error,
  },
  text: {
    primary: color.textPrimary,
  },
}

/* Breakpoints */

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: global.maxContentWidth,
    xl: 1920,
  },
}

/* Typography */

const typography = {
  fontFamily: 'Poppins',
  htmlFontSize: 16,
  fontSize: 16,
  color: 'textPrimary',
  h1: {
    fontSize: '4rem',
    fontWeight: 300,
  },
  h2: {
    fontWeight: 300,
  },
  h3: {
    fontWeight: 300,
  },
  h4: {
    fontWeight: 300,
  },
  h5: {
    fontWeight: 300,
  },
  h6: {
    fontWeight: 300,
  },
  body1: {
    fontWeight: 300,
  },
  body2: {
    fontWeight: 300,
  },
  subtitle: {
    fontWeight: 300,
  },
  subtitle2: {
    fontWeight: 300,
  },
  caption: {
    fontWeight: 300,
  },
}

/* Utils */

const utils = {
}

/* Props & overrides */

const props = {
  MuiButtonBase: {
    // The properties to apply
    disableRipple: false, // Set to true for no more ripple, on the whole application 💣!
  },
  MuiCircularProgress: {
    size: 28,
    thickness: 4,
  },
  MuiInput: {
    autoComplete: 'off',
  },
  MuiCheckbox: {
    color: 'primary',
  },
  MuiPaper: {
    square: true,
  },
  MuiRadio: {
    color: 'primary',
  },
  MuiSwitch: {
    color: 'primary',
  },
}

const overrides = {
  MuiButton: {
    root: {
      fontWeight: '600',
      textTransform: 'none',
    },
    contained: {
      borderRadius: '1.25rem',
    },
  },
  MuiTimelineItem: {
    missingOppositeContent: {
      '&:before': {
        display: 'none',
      },
    },
  },
  MuiLink: {
    root: {
      wordBreak: 'break-word',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '1.25rem',
    },
  },
}

export default {
  color,
  palette,
  typography,
  global,
  breakpoints,
  utils,
  props,
  overrides,
}
