export default (theme) => {
  return {
    footer: {
      padding: '2rem',
      backgroundColor: theme.color.primaryDark,

      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },

    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    footerSocial: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '10rem',

      [theme.breakpoints.down('sm')]: {
        margin: '1rem auto',
      },
    },

    iconSocial: {
      color: theme.color.white,
      fontSize: '3rem',
    },

    linkText: {
      color: theme.color.white,
      padding: '0.6rem',
    },

    termsPolicyContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '42rem',
      fontSize: '0.875rem',
      lineHeight: '1.3rem',
      letterSpacing: '0.1rem',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '0.625rem',
      },
    },

    footerCopyright: {
      color: theme.color.white,
      display: 'flex',
      justifyContent: 'center',
      letterSpacing: '0.1rem',

      [theme.breakpoints.down('xs')]: {
        fontSize: '0.625rem',
      },
    },
  }
}
