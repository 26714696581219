import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Container,
  List,
  ListItem,
  Drawer,
} from '@material-ui/core'
import { Dehaze } from '@material-ui/icons'

import Link from '~/src/components/link'

import styles from './styles'

const useStyles = makeStyles(styles)

const navLink = [
  { title: 'Home', link: '/' },
  // { title: 'Events', link: '/events' },
  // { title: 'Portfolio', link: '/portfolio' },
  { title: 'Services', link: '/services' },
  { title: 'Contact', link: '/#contact' },
]

const Logo = React.memo(() => {
  return (
    <Link to="/">
      <StaticImage
        src="../../images/logo.png"
        alt="MAJIN logo"
        placeholder="blurred"
        layout="fixed"
        width={200}
        height={60}
      />
    </Link>
  )
})

const NavBar = () => {
  const classes = useStyles()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navbarHidden = () => (
    <div
      onClick={handleDrawerToggle}
    >
      <List style={{ padding: 0 }}>
        <ListItem>
          <Logo />
        </ListItem>
        {navLink.map((nav) => (
          <ListItem
            key={nav.title}
            className={classes.listItem}
          >
            <Link
              to={nav.link}
              key={nav.title}
              className={classes.linkListItem}
              activeClassName={classes.activeLink}
            >
              {nav.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <AppBar
      position="static"
      color="transparent"
      style={{ boxShadow: 'none' }}
    >
      <Container maxWidth="lg">
        <Toolbar
          className={classes.toolbar}
          classes={{
            gutters: classes.gutters,
          }}
        >
          <div className={classes.toolbarTitle}>
            <Logo />
          </div>
          <Hidden mdUp>
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.iconButton}
            >
              <Dehaze fontSize="small" />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <nav>
              {navLink.map(nav => (
                <Link
                  to={nav.link}
                  key={nav.title}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                >
                  {nav.title}
                </Link>
              ))}
            </nav>
          </Hidden>
        </Toolbar>
        <Drawer
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          {navbarHidden()}
        </Drawer>
      </Container>
    </AppBar>
  )
}

export default NavBar
